<template>
  <div class="detail-page">
    <div class="detail-page__left"
         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
      <div class="admin-edit" @click="editTranslate(['poshta_orderCDEK'])"></div>
      <CardLeftBlock
          :backgroundImage="'orders'"
      >
        <template slot="body">
          <h2 class="mt-0">
            {{ $t('poshta_orderCDEK.localization_value.value') }}
          </h2>
          <div class="card-detail-left__date">
            {{ new Date() | moment("DD MMM, YYYY") }}
          </div>

        </template>
      </CardLeftBlock>
    </div>

    <div class="detail-page__right">
      <CardRightBlock>
        <template slot="header">
          <div class="order-create__head">
            <div class="order-create__head-line">
              <div class="order-create__header-col"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['poshta_backTo',])"></div>
                <router-link :to="isAdmin ? $store.getters.GET_PATHS.cdek : $store.getters.GET_PATHS.expressOrders"
                             class="order-create__head-back">
                  <LinkBack
                          :value="$t('poshta_backTo.localization_value.value')"
                  />
                </router-link>
              </div>
            </div>
            <div class="order-create__head-title"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate(['poshta_createCDEK'])"></div>
              {{ $t('poshta_createCDEK.localization_value.value') }}
<!--              Create Order CDEK-->
            </div>
            <div class="order-create__head-txt mb-0"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate(['poshta_CDEKPleaseInformation'])"></div>
              {{ $t('poshta_CDEKPleaseInformation.localization_value.value') }}
<!--              Please, fill out all the information below to complete the order for the recepient-->
            </div>

          </div>
        </template>

        <template slot="body">
          <div class="order-create__section">
            <div class="custom-row" v-if="$store.getters.getIsAdminRights === 'admin'">
              <div class="custom-col custom-col--33 custom-col--md-100">
                <UserSelect
                    :validationUser="cdek.validation.user"
                    :validationTxtUser="cdek.validationTxt.user"
                    :validationTranslateUser="cdek.validationTranslate.user"
                    :userSelected="cdek.data.user"
                    @changeUser="changeUser"
                    :typeSelect="'users'"
                    :userEmail="true"
                />
              </div>
            </div>
            <div class="custom-row">
              <div class="custom-col custom-col--33 custom-col--md-100"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['poshta_previouslyUsedSenders'])"></div>
                <DefaultSelect
                        :options="sendersCDEK"
                        :optionsLabel="'address'"
                        :label="$t('poshta_previouslyUsedSenders.localization_value.value')"
                        @change="(item) => $emit('changeOldSenders', item)"
                />
              </div>
            </div>
          </div>

          <StepOne
              :cdek="cdek"
              :personalInformation="cdek.data.personalInformation"
              :packaging="cdek.data.packaging"
          />
        </template>
        <template slot="footer">
          <div class="order-create__footer">

            <div class="order-create__footer-link"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit"
                   @click="editTranslate(['common_cancel'])"></div>
              <router-link :to="$store.getters.GET_PATHS.expressOrders"
                           class="site-link site-link--alt"
              >
                {{ $t('common_cancel.localization_value.value') }}
              </router-link>
            </div>

            <div class="order-create__footer-btn ml-auto"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate(['common_SaveInDrafts', 'common_drafts', 'common_savingInDrafts', 'poshta_BuyLabel'])"></div>
              <MainButton
                  class="order-create__footer-btn-i secondary"
                  :value="$t('common_SaveInDrafts.localization_value.value')"
                  :tooltip="true"
                  @click.native="$emit('create', true)"
              >
                <template slot="tooltip">
                  <p>
                    <b>{{$t('common_drafts.localization_value.value')}}</b>
                  </p>
                  <p>
                    {{$t('common_savingInDrafts.localization_value.value')}}
                  </p>
                </template>
              </MainButton>

              <MainButton
                  class="order-create__footer-btn-i wfc"
                  :value="$t('poshta_BuyLabel.localization_value.value')"
                  @click.native="$emit('getDeliveryPrice')"
              />
            </div>

          </div>
        </template>
      </CardRightBlock>

    </div>

  </div>
</template>

<script>
  import CardLeftBlock from "../../../../../../components/coreComponents/CardLeftBlock/CardLeftBlock.vue";
  import MainButton from "../../../../../../components/UI/buttons/MainButton/MainButton";
  import CardRightBlock from "../../../../../coreComponents/CardRightBlock/CardRightBlock.vue";
  import LinkBack from "../../../../../UI/buttons/LinkBack/LinkBack.vue";
  import StepOne from "../../components/StepOne/StepOne";
  import UserSelect from "../../../../../coreComponents/UserSelect/UserSelect";
  import {cdekOrdersMixins} from "../../../../../../mixins/internalOrdersMixins/сdekOrdersMixin";
  import DefaultSelect from "../../../../../UI/selectiones/DefaultSelect/DefaultSelect";

  export default {
    name: "CDEKCreationUser",

    components: {
      DefaultSelect,
      CardLeftBlock,
      MainButton,
      CardRightBlock,
      LinkBack,
      StepOne,
      UserSelect,
    },

    mixins: [
      cdekOrdersMixins
    ],

    props: {
      cdek: Object,
      sendersCDEK: Array,
    },

    data() {
      return{
        options: [{}],
      }
    },

    methods:{


    }
  }
</script>

<style lang="scss">

  .product-progress-steps{
    max-width: 625px;
  }

</style>
