<template>
  <div class="table-filter-wrap">
    <template v-if="$store.getters.getOrdersInternal.length > 0 && $store.getters.getOrdersInternalLoading !== true">
      <div class="site-table-wrap small-table"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate([
          'poshta_ID',
          'poshta_Created',
          'common_user',
          'poshta_TrackNumber',
          'poshta_Shipping',
          'common_manage',
          'common_edit',
          'common_delete',
          'express_CDEKCreateLabel',
          'express_CDEKBarcode',
          'express_CDEKWaybill',
        ])"></div>
        <table class="site-table" v-if="!isMobileFunc()">
          <thead>
          <tr>
            <th>{{$t('poshta_ID.localization_value.value')}}</th>
            <th>{{$t('poshta_Created.localization_value.value')}}</th>
            <th v-if="isAdmin"> {{$t('common_user.localization_value.value')}}</th>
            <th>{{$t('poshta_TrackNumber.localization_value.value')}}</th>
            <th>{{$t('poshta_Shipping.localization_value.value')}}, $</th>
            <th width="100%"></th>
            <th>{{$t('common_manage.localization_value.value')}}</th>
          </tr>
          </thead>
          <tbody>

          <tr v-for="(item, index) in $store.getters.getOrdersInternal" :key="index">
            <td>
              <div class="table-row">
                <router-link
                        class="table-link btn-style"
                        :to="$store.getters.GET_PATHS.cdek + '/show/' + item.id"
                >
                  #{{item.id}}
                </router-link>
              </div>
            </td>
            <td>
              {{ item.created_at | moment("DD MMM, YYYY") }}
            </td>

            <td v-if="isAdmin">
              <TableUserColumn
                      v-if="isAdmin"
                      :item="item"
              />
            </td>

            <td>
              <InternalTrackingNumber
                      :item="item"
                      @reload="$emit('reload')"
              />
            </td>

            <td>
              <ValueHelper
                      :value="item.shipping_cost"
              />
            </td>


            <td width="100%"></td>
            <td>
              <div class="table-right table-manage-list table-manage-list--small"
                   v-if="$store.getters.getGlobalUpdateTable"
              >
                <ManagerButton @reloadManagerBtn="$store.commit('setGlobalUpdateManagerButton', Math.random()) "
                               :key="$store.getters.getGlobalUpdateManagerButton"
                               class="width-scoped"
                >
                  <template slot="item"
                            v-if="(item.order_internal_status_id !== INTERNAL_STATUSES.COMPLETE.id) ||
                              $store.getters.getIsAdminRights === 'admin'"
                  >
                    <LinkButton
                            :value="$t('common_edit.localization_value.value')"
                            :type="'edit'"
                            :link="$store.getters.GET_PATHS.cdek + '/' + item.id"
                    />
                  </template>

                  <template slot="item"
                            v-if="(item.order_internal_status_id !== INTERNAL_STATUSES.COMPLETE.id)"
                  >
                    <LinkButton
                            :value="$t('common_delete.localization_value.value')"
                            :type="'delete'"
                            @click.native="removeOrderInternal(item.id)"
                    />
                  </template>

                  <template slot="item"
                            v-if="(item.order_internal_status_id !== INTERNAL_STATUSES.COMPLETE.id)"
                  >
                    <LinkButton
                            :value="$t('express_CDEKCreateLabel.localization_value.value')"
                            :type="'dog'"
                            @click.native="createLabel(item.id, true)"
                    />
                  </template>

                  <template slot="item"
                            v-if="(item.order_internal_status_id === INTERNAL_STATUSES.COMPLETE.id)"
                  >
                    <LinkButton
                            :link="$store.getters.GET_PATHS.getPdfFile + '/' + getBarcodeUUID(item.file_entries)"
                            :value="$t('express_CDEKBarcode.localization_value.value')"
                            :type="'dog'"
                            :target="'_blank'"
                    />
                  </template>
                  <template slot="item"
                            v-if="(item.order_internal_status_id === INTERNAL_STATUSES.COMPLETE.id)"
                  >
                    <LinkButton
                            :link="$store.getters.GET_PATHS.getPdfFile + '/' + getCDEKWaybillUUID(item.file_entries)"
                            :value="$t('express_CDEKWaybill.localization_value.value')"
                            :type="'dog'"
                            :target="'_blank'"
                    />
                  </template>

                </ManagerButton>
              </div>
            </td>
          </tr>

          </tbody>
        </table>

        <div class="table-card" v-if="isMobileFunc()">
          <div class="table-card__list">
            <div class="table-card__item"
                 v-for="(item, index) in $store.getters.getOrdersInternal"
                 :key="index"
            >
              <CDEKTableMobile
                      :item="item"
                      @removeOrderInternal="removeOrderInternal(item.id)"
                      @createLabel="createLabel(item.id, true)"
                      @barcodeCDEK="barcodeCDEK(item.id)"
                      @waybillCDEK="waybillCDEK(item.id)"
                      @reload="$emit('reload')"
              />
              <!--                  @openPoshtaLink="openPoshtaLink"-->
              <!--                  @tableCreateLabel="tableCreateLabel(item)"-->
            </div>
          </div>
        </div>


        <div class="table-bottom-btn">
          <div class="table-bottom-btn__inner">
            <ShowMore
                    v-if="$store.getters.getOrdersInternalCommonList.next_page_url !== null && $store.getters.getOrdersInternal.length > 0"
                    @click.native="$emit('showMore')"
                    v-bind:class="{'disabled-btn' : $store.getters.getNextOrdersInternalPage}"
                    :count="$store.getters.getOrdersInternalCommonList.total - $store.getters.getOrdersInternalForPage * $store.getters.getOrdersInternalCommonList.current_page < $store.getters.getOrdersInternalForPage ?
                  $store.getters.getOrdersInternalCommonList.total - $store.getters.getOrdersInternalForPage * $store.getters.getOrdersInternalCommonList.current_page:
                  $store.getters.getOrdersInternalForPage"
            />
          </div>
        </div>

      </div>
    </template>

    <template v-if="$store.getters.getOrdersInternalLoading === false && $store.getters.getOrdersInternal.length === 0">
      <NoResult
              :countFilterParams="countFilterParams"
              :title="$t('common_noDataYet.localization_value.value')"
              :titleWithFilter="$t('common_noResult.localization_value.value')"
              :text="$t('common_noDataYetTxt.localization_value.value')"
              :textWithFilter="$t('common_noResultSeems.localization_value.value')"
              @resetFilter="$emit('resetFilter')"
      />
    </template>


    <PayOrderPopup
            v-if="payOrderPopup"
            :orderPrice="orderPrice"
            :disableBtn="$store.getters.getCDEKBtn || $store.getters.getOrdersInternalBtn"
            @close="changePayOrderPopup(false)"
            @createLabel="save"
    />


    <!--<ProductsToWarehouse @close="closePopup" v-if="isModalProductsToWarehouse"/>-->


  </div>
</template>

<script>
  import ShowMore from "../../../../../../coreComponents/Buttons/ShowMore/ShowMore";
  import LinkButton from "../../../../../../UI/buttons/LinkButton/LinkButton";
  import ManagerButton from "../../../../../../UI/buttons/ManagerButton/ManagerButton";
  import {mixinDetictingMobile} from '../../../../../../../mixins/mobileFunctions'
  import NoResult from "../../../../../../coreComponents/NoResult/NoResult";
  import ValueHelper from "../../../../../../coreComponents/ValueHelper/ValueHelper";
  import {
    cdekGetLabelMixin,
    cdekOrdersTableMixins
  } from "../../../../../../../mixins/internalOrdersMixins/сdekOrdersMixin";
  import {INTERNAL_STATUSES} from "../../../../../../../staticData/staticVariables";
  import CDEKTableMobile from "./CDEKTableMobile/CDEKTableMobile";
  import PayOrderPopup from "../../../../../../coreComponents/Popups/PayOrderPopup/PayOrderPopup";
  import TableUserColumn from "../../../../../../coreComponents/TableComponents/TableUserColumn/TableUserColumn";
  import InternalTrackingNumber
    from "../../../../../../coreComponents/TableComponents/InternalTrackingNumber/InternalTrackingNumber";


  export default {
    name: "CDEKTable",

    components: {
      InternalTrackingNumber,
      TableUserColumn,
      ShowMore,
      LinkButton,
      ManagerButton,
      NoResult,
      ValueHelper,
      CDEKTableMobile,
      PayOrderPopup,
    },

    mixins: [mixinDetictingMobile, cdekOrdersTableMixins, cdekGetLabelMixin],

    props: {
      selectedNow: Boolean,
      countFilterParams: Number,
    },

    data() {
      return {
        orderId: '',
        showFilter: false,
        isMobile: false,

        showContent: false,

        // isModalProductsToWarehouse: false,

        show1: false,


        INTERNAL_STATUSES: INTERNAL_STATUSES,

        payOrderPopup: false,
        orderPrice: -1,

      }
    },

    methods: {

      barcodeCDEK(item) {
        this.getCDEKProductLabel(item)
      },

      waybillCDEK(item) {
        this.getCDEKOrderLabel(item)
      },


      cantDeleteProduct() {
        let text = {
          title: 'Ooops',
          txt: 'Sorry, you cannot uninstall this product',
          no: 'Ok'
        }

        let confirm = () => {
          this.$store.commit('setConfirmDeletePopup', false)
          return true
        }

        this.deleteFunc(text, confirm)
      },

      removeProduct() {
        let text = {
          title: 'Delete Product?',
          txt: 'Are you sure you want to Delete Product?',
          yes: 'common_confirmDelete',
          no: 'common_confirmNo'
        }

        let confirm = () => {

          // this.$store.dispatch('deleteShopPermission', id).then((response) => {
          //   if (this._.has(response, 'data') && response.status === this.$store.getters.GET_ERRORS.SUCCESS_CODE) {
          //     let shops = this.$store.getters.getShops
          //     shops.map((item, index) => {
          //       if(item.id === id) {
          //         shops[index].shop_oauth_param.key_and_token_exist = 0
          //       }
          //     })
          //   } else {
          //     setTimeout(() => {
          //       this.openNotify('success', 'Произошла ошибка')
          //     }, 200)
          //   }
          // })

          this.$store.commit('setConfirmDeletePopup', false)
          return true
        }

        this.deleteFunc(text, confirm)


      }
    }

  }

</script>

<style scoped lang="scss">

  .site-table thead th:first-child,
  .site-table tbody td:first-child {
    padding-right: 0;
  }

  .site-table thead th:last-child,
  .site-table tbody td:last-child {
    padding-right: 0;
  }


  .small-table td:last-child {
    white-space: break-spaces !important;
  }

  .width-scoped {
    width: 300px;
    max-width: 300px;

    @media (max-width: 1740px) {
      width: 200px;
      max-width: 200px;
    }
  }

</style>
