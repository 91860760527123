<template>
  <div class="order-create__section">
    <div class="order-create__section-label section-label"
         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
      <div class="admin-edit" @click="editTranslate(['poshta_PersonalInformation'])"></div>
      {{$t('poshta_PersonalInformation.localization_value.value')}}
    </div>
    <div class="order-create__row custom-row">
      <div class="order-create__col custom-col custom-col--33 custom-col--md-50 custom-col--sm-100"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
           <div class="admin-edit" @click="editTranslate(['poshta_contactName', ])"></div>
        <DefaultInput
            :label="$t('poshta_contactName.localization_value.value')"
                :type="'text'"
                v-bind:class="{'ui-no-valid': personalInformation.validation.personalName}"
                :errorTxt="$t(`${personalInformation.validationTranslate.personalName}.localization_value.value`)"
                :error="personalInformation.validation.personalName"
                v-model="personalInformation.information.personalName"
        />
      </div>

      <div class="order-create__col custom-col custom-col--33  custom-col--md-50 custom-col--sm-100"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['common_phone', ])"></div>
        <DefaultInput
                :label="$t('common_phone.localization_value.value')"
                :type="'text'"
                v-bind:class="{'ui-no-valid': personalInformation.validation.personalPhone}"
                :errorTxt="$t(`${personalInformation.validationTranslate.personalPhone}.localization_value.value`)"
                :error="personalInformation.validation.personalPhone"
                v-model="personalInformation.information.personalPhone"
        />
      </div>
    </div>

    <div class="order-create__section-label section-label"
         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
      <div class="admin-edit" @click="editTranslate(['poshta_SenderAddress', 'poshta_SenderAddressTooltip'])"></div>
      {{ $t('poshta_SenderAddress.localization_value.value') }}
      <v-popover
              class="site-tooltip"
              offset="5"
              placement="top"
              trigger="hover"

      >
        <TooltipBtn
                class="tooltip-target"

        />
        <template slot="popover">
          <p>
            <b>{{ $t('poshta_SenderAddress.localization_value.value') }}</b>
          </p>
          <p>
            {{$t('poshta_SenderAddressTooltip.localization_value.value')}}
          </p>
        </template>
      </v-popover>
    </div>

    <div class="order-create__section-sub">
      <div class="order-create__row custom-row">
        <div class="order-create__col custom-col custom-col--33  custom-col--md-50 custom-col--sm-100"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate(['poshta_city'])"></div>
          <SearchSelect
              :options="cities"
              :label="$t('poshta_city.localization_value.value')"
              v-bind:class="{'ui-no-valid': cdek.validation.selectedCity}"
              :errorTxt="$t(`${cdek.validationTranslate.selectedCity}.localization_value.value`)"
              :error="cdek.validation.selectedCity"
              :functionSearch="functionSearchCDEKCity"
              :optionsLabel="'cityName'"
              :selected="cdek.data.selectedCity"
              @change="selectCDEKCity"
          />
        </div>

        <div class="order-create__col custom-col custom-col--33  custom-col--md-50 custom-col--sm-100"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate(['poshta_office'])"></div>

          <DefaultSelect
              :options="offices"
              :label="$t('poshta_office.localization_value.value')"
              v-bind:class="{'ui-no-valid': cdek.validation.selectedOffice}"
              :errorTxt="$t(`${cdek.validationTranslate.selectedOffice}.localization_value.value`)"
              :error="cdek.validation.selectedOffice"
              @change="selectCDEKOffice"
              :optionsLabel="'address'"
              :selected="cdek.data.selectedOffice"
          />

        </div>
        <div class="order-create__col custom-col custom-col--16  custom-col--md-25 custom-col--sm-50"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate(['common_zipCode', ])"></div>
          <DefaultInput
              :label="$t('common_zipCode.localization_value.value')"
                  :type="'text'"
                  v-bind:class="{'ui-no-valid': cdek.validation.zip}"
                  :errorTxt="$t(`${cdek.validationTranslate.zip}.localization_value.value`)"
                  :error="cdek.validation.zip"
                  v-model="cdek.data.zip"
          />
        </div>
      </div>
    </div>

    <div class="order-create__section-label section-label"
         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
      <div class="admin-edit" @click="editTranslate(['poshta_shippingInformation', ])"></div>
      {{$t('poshta_shippingInformation.localization_value.value')}}
    </div>


    <div class="order-create__section-sub">
      <div class="order-create__row custom-row">
        <div class="order-create__col custom-col custom-col--33 custom-col--md-50 custom-col--xs-100"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate(['poshta_contactName', ])"></div>
          <DatePickerDefault
              :label="$t('poshta_ShippingDate.localization_value.value')"
              :value="cdek.data.shippingDate"
              v-bind:class="{'ui-no-valid': cdek.validation.shippingDate}"
              :errorTxt="$t(`${cdek.validationTranslate.shippingDate}.localization_value.value`)"
              :error="cdek.validation.shippingDate"
          >
            <template slot="body">
              <date-picker
                  v-model="cdek.data.shippingDate"
                  valueType="format"
                  :lang="$store.getters.getDatePickerTranslations[$store.getters.GET_LANG]"
                  :readonly="true"
              ></date-picker>
            </template>
          </DatePickerDefault>
        </div>
        <div class="order-create__col custom-col custom-col--33  custom-col--md-50 custom-col--sm-100"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate(['poshta_ShipToWarehouse', ])"></div>
          <DefaultInput
                  :label="$t('poshta_ShipToWarehouse.localization_value.value')"
                  :type="'text'"
                  v-bind:class="{'ui-no-valid': cdek.validation.warehouse}"
                  :errorTxt="$t(`${cdek.validationTranslate.warehouse}.localization_value.value`)"
                  :error="cdek.validation.warehouse"
                  v-model="cdek.data.warehouse"
                  :disabled="true"
          />
        </div>
        <div class="order-create__col custom-col custom-col--16  custom-col--md-25 custom-col--sm-50"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate(['poshta_valueRUB', ])"></div>
          <DefaultInput
                  :label="$t('poshta_valueRUB.localization_value.value')"
                  :type="'text'"
                  v-bind:class="{'ui-no-valid': cdek.validation.assessedValue}"
                  :errorTxt="$t(`${cdek.validationTranslate.assessedValue}.localization_value.value`)"
                  :error="cdek.validation.assessedValue"
                  v-model="cdek.data.assessedValue"
          />
        </div>

        <div class="order-create__col custom-col custom-col--16  custom-col--md-0 mb-0">
        </div>
      </div>

      <PackagingBlock
          :userId="cdek.data.userId"
          :packaging="cdek.data.packaging"
      />
    </div>

  </div>
</template>

<script>

  // import DefaultSelect from "../../../../../../../UI/selectiones/DefaultSelect/DefaultSelect";
  import {mixinDetictingMobile} from "../../../../../../mixins/mobileFunctions";
  import DefaultInput from "../../../../../UI/inputs/DefaultInput/DefaultInput";
  import TooltipBtn from "../../../../../UI/tooltips/TooltipBtn/TooltipBtn";
  import SearchSelect from "../../../../../UI/selectiones/SearchSelect/SearchSelect";
  import DefaultSelect from "../../../../../UI/selectiones/DefaultSelect/DefaultSelect";
  import DatePickerDefault from "../../../../../UI/inputs/DatePickerDefault/DatePickerDefault";
  import DatePicker from 'vue2-datepicker'
  import PackagingBlock from "../../../../../coreComponents/PackagingBlock/PackagingBlock";


  export default {
    name: "StepOne",
    components: {
      SearchSelect,
      // DefaultSelect,
      DefaultInput,
      TooltipBtn,
      DefaultSelect,
      DatePickerDefault,
      DatePicker,
      PackagingBlock,
    },

    mixins: [mixinDetictingMobile],

    props: {
      cdek: Object,
      personalInformation: Object,
      // deliveryAddress: Object,
      packaging: Object,
    },


    data() {
      return {
        options: [],
        cities: [],
        offices: [],
        officeId: '',
        isModalCustomLimitsPopup: false,
      }
    },


    mounted() {

    },

    methods: {

      functionSearchCDEKCity(val, loading) {
        loading(true)

        if (this.proformSearchTimeoutTime !== undefined) {
          let dateNow = new Date()
          if (dateNow - this.proformSearchTimeoutTime < 500) {
            clearTimeout(this.proformSearchTimeout)
          }
        }

        this.createTimeOut(val, loading)
      },

      createTimeOut(val, loading) {
        this.proformSearchTimeoutTime = new Date()
        this.proformSearchTimeout = setTimeout(() => {
          this.getCities(val, loading)
        }, 500)
      },

      getCities(val = false, loading){

        let data = {
          search: val ? val : ''
        }

        if(data.search  && data.search.length < 3) return

        if (data.search === '') {
          loading(false)
          return;
        }

        this.$store.dispatch('getCDEKCities', data).then((response) => {
          if (response?.response?.status === this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE) {
            if (loading)
              loading(false)
            return
          }

          this.cities = response

          if (loading)
            loading(false)
        })
      },

      selectCDEKCity(val) {
        this.cdek.setSelectedCity(val)
        console.log(789789, val);
        // this.cdek.setCity(val)

        if(!val) {
          this.offices = []
          // this.selectedOffice = {}
          this.cdek.setSelectedOffice('')
          this.cdek.setZip('')
          return
        }

        let data = {
          "city_code" : val.id
        }

        this.$store.dispatch('getCDEKOffices', data).then((response) => {
          this.offices = response
          // this.selectedOffice = ''
          this.cdek.setSelectedOffice('')
          this.cdek.setZip('')
        })
      },

      selectCDEKOffice(val){
        this.cdek.setSelectedOffice(val)
        this.cdek.setZip(val.postalCode)
      },

    },
  }
</script>

<style scoped>

</style>
