<template>
  <div class="fragment">


    <div class="content-top-line content-top-line--with-bottom-line">
      <div class="content-tabs content-tabs--separator">

      </div>

      <div class="content-top-line-wrap">
        <ToggleFilterButton :count="countFilterParams" @toggleFilter="$emit('toggleFilter')" :showFilter="showFilter" />
      </div>
    </div>

    <div class="light-bg-block">

      <div class="btn-right-block">
<!--        <router-link :to="$store.getters.GET_PATHS.cdekCreation">-->
<!--          <MainButton class="btn-fit-content ml-3"-->
<!--                      :value="'Add New'"-->
<!--                      :ico="'ico'"-->
<!--          >-->
<!--            <template slot="ico">-->
<!--              <PlusIconSVG class="btn-icon-plus"/>-->
<!--            </template>-->
<!--          </MainButton>-->
<!--        </router-link>-->
      </div>
    </div>



  </div>
</template>



<script>
  import ToggleFilterButton from "../../../../../../UI/buttons/ToggleFilterButton/ToggleFilterButton";
  // import MainButton from "../../../../../../UI/buttons/MainButton/MainButton";
  // import PlusIconSVG from '../../../../../../../../public/img/UI-group/plus-icon.svg?inline'


  export default {
    name: "CDEKHead",
    components: {
      ToggleFilterButton,
      // MainButton,
      // PlusIconSVG,
    },

    props: [
      'showFilter',
      'activeTab'
    ],

    watch: {
      activeTab(newVal) {
        this.tab = newVal
      }
    },

    data(){
      return{
        tab: this.activeTab,
        countFilterParams: 0,
      }
    },

    methods: {

      changeTab(id) {
        this.$emit('changeTab', id)
      },

      changeDate() {
        console.log('changeDate function');
      },

      openHTMLCodePopup(){
        this.changeGenPayPalBtnPopup(false)
        this.changeCodeHTMLPopup(true)
      }
    }
  }
</script>

<style scoped lang="scss">
  @import "../../../../../../../scss/mixins/mixins";

  .light-bg-block{
    display: flex;
    flex-wrap: wrap;
  }

  @include for-992{
    .btn-left-block {
      margin-bottom: 15px;
    }

    .btn-right-block{
      flex-wrap: wrap;
    }

    .main-button{
      margin-bottom: 10px;
    }

  }

  @include for-420{
    .light-bg-block{
      flex-direction: column-reverse;
      align-items: flex-start;
    }

  }

</style>

