
<template>
  <div class="detail-page">
    <div class="detail-page__left"
         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
      <div class="admin-edit" @click="editTranslate(['poshta_order','poshta_Created'])"></div>
      <CardLeftBlock
          :name="$t('poshta_order.localization_value.value')"
          :value="`#${cdek.data.id}`"
          :backgroundImage="'orders'"
      >
        <template slot="body">
          <div class="d-flex mt-3">
            <div class="card-detail-left__name">
              {{ $t('poshta_Created.localization_value.value') }}
            </div>
            <div class="card-detail-left__date ml-2">{{cdek.data.shippingDate}}</div>
          </div>

        </template>
      </CardLeftBlock>
    </div>

    <div class="detail-page__right">
      <CardRightBlock>
        <template slot="header">
          <div class="order-create__head">
            <div class="order-create__head-line">
              <div class="order-create__header-col"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['poshta_backTo',])"></div>
                <router-link :to="isAdmin ? $store.getters.GET_PATHS.cdek : $store.getters.GET_PATHS.expressOrders"
                             class="order-create__head-back">
                  <LinkBack
                      :value="$t('poshta_backTo.localization_value.value')"
                  />
                </router-link>
              </div>
            </div>

            <div class="order-create__head-title"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate(['poshta_editCDEK'])"></div>
              {{ $t('poshta_editCDEK.localization_value.value') }}
<!--              Edit Order CDEK-->
            </div>
          </div>
        </template>

        <template slot="body">
          <div class="order-create__section">
            <div class="custom-row" v-if="$store.getters.getIsAdminRights === 'admin'">
              <div class="custom-col custom-col--33 custom-col--md-100">
                <UserSelect
                    :validationUser="cdek.validation.user"
                    :validationTxtUser="cdek.validationTxt.user"
                    :validationTranslateUser="cdek.validationTranslate.user"
                    :userSelected="cdek.data.user"
                    @changeUser="changeUser"
                    :typeSelect="'users'"
                    :userEmail="true"
                />
              </div>
            </div>
            <div class="custom-row">
              <div class="custom-col custom-col--33 custom-col--md-100"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['poshta_previouslyUsedSenders'])"></div>
                <DefaultSelect
                        :options="sendersCDEK"
                        :optionsLabel="'address'"
                        :label="$t('poshta_previouslyUsedSenders.localization_value.value')"
                        @change="(item) => $emit('changeOldSenders', item)"
                />
              </div>
            </div>
          </div>

          <StepOne
              :cdek="cdek"
              :personalInformation="cdek.data.personalInformation"
              :packaging="cdek.data.packaging"
          />

        </template>
        <template slot="footer">
          <div class="order-create__footer">

            <div class="order-create__footer-link"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit"
                   @click="editTranslate(['common_cancel'])"></div>
              <router-link :to="$store.getters.GET_PATHS.expressOrders"
                           class="site-link site-link--alt"
              >
                {{ $t('common_cancel.localization_value.value') }}
              </router-link>
            </div>

            <div class="order-create__footer-btn ml-auto"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate(['common_SaveInDrafts', 'common_drafts', 'common_savingInDrafts', 'poshta_BuyLabel'])"></div>
              <MainButton
                  class="order-create__footer-btn-i secondary"
                  :value="$t('common_SaveInDrafts.localization_value.value')"
                  :tooltip="true"
                  @click.native="$emit('edit')"
              >
                <template slot="tooltip">
                  <p>
                    <b>{{$t('common_drafts.localization_value.value')}}</b>
                  </p>
                  <p>
                    {{$t('common_savingInDrafts.localization_value.value')}}
                  </p>
                </template>
              </MainButton>

              <MainButton
                  class="order-create__footer-btn-i wfc"
                  :value="$t('poshta_BuyLabel.localization_value.value')"
                  @click.native="$emit('getDeliveryPrice')"
              />
            </div>

          </div>
        </template>
      </CardRightBlock>
    </div>

  </div>
</template>

<script>
import CardLeftBlock from "../../../../../coreComponents/CardLeftBlock/CardLeftBlock.vue";
import CardRightBlock from "../../../../../coreComponents/CardRightBlock/CardRightBlock.vue";
import LinkBack from "../../../../../UI/buttons/LinkBack/LinkBack";
import MainButton from "../../../../../UI/buttons/MainButton/MainButton";
import {cdekOrdersMixins} from "../../../../../../mixins/internalOrdersMixins/сdekOrdersMixin";
import UserSelect from "../../../../../coreComponents/UserSelect/UserSelect";
import StepOne from "../../components/StepOne/StepOne";
import DefaultSelect from "../../../../../UI/selectiones/DefaultSelect/DefaultSelect";

export default {
  name: "CDEKEditing",

  mixins: [cdekOrdersMixins],

  components: {
    DefaultSelect,
    CardLeftBlock,
    CardRightBlock,
    LinkBack,
    MainButton,
    UserSelect,
    StepOne,
  },

  props: {
    cdek: Object,
    sendersCDEK: Array,
  },
}
</script>

<style scoped lang="scss">
@import "../../../../../../scss/mixins/mixins";

@include for-680{
  .order-create__footer-link{
    width: 100%;
  }
}

</style>
