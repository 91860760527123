<template>
  <div class="fragment">
    <CDEKCreationUser
        :cdek="CDEK"
        :sendersCDEK="sendersCDEK"
        @create="save"
        @getDeliveryPrice="getDeliveryPrice"
        @changeOldSenders="changeOldSenders"
    />

    <PayOrderPopup
        v-if="payOrderPopup"
        :orderPrice="orderPrice"
        :disableBtn="$store.getters.getCDEKBtn || $store.getters.getOrdersInternalBtn"
        @close="changePayOrderPopup(false)"
        @createLabel="save"
    />
  </div>
</template>

<script>
  import CDEKCreationUser from "./CDEKCreationUser/CDEKCreationUser";
  import {CDEK} from "../../models/CDEK";
  import {cdekOrdersMixins} from "../../../../../mixins/internalOrdersMixins/сdekOrdersMixin";
  import {CDEK_DELIVERY_SERVICE, ORDER_TYPE_EXPRESS} from "../../../../../staticData/staticVariables";
  import PayOrderPopup from "../../../../coreComponents/Popups/PayOrderPopup/PayOrderPopup";


  export default {
    name: "CDEKCreation",
    components: {
      CDEKCreationUser,
      PayOrderPopup,
    },

    mixins: [cdekOrdersMixins],


    watch: {

      loadUserRoleAndAuthorizedData: function (val) {
        if(val){
          this.getUserData()
        }
      },
    },

    data() {
      return {
        CDEK: new CDEK(),

        payOrderPopup: false,
        orderPrice: -1,

        sendersCDEK: [],
      }
    },

    mounted() {
      if (this.loadUserRoleAndAuthorizedData) {
        this.getUserData()
      }
    },

    methods: {

      changeOldSenders(item) {
        this.CDEK.setOldSenderData(item)
      },

      getUserData() {
        if(this.$route.params.type && this.$route.params.id){
          let id = this.$route.params.id
          let type = this.$route.params.type
          switch (type){
            case ORDER_TYPE_EXPRESS:
              this.CDEK.setOrderType(id, type)
              this.getDataExpress(id).then(() => {
                let express = this.$store.getters.getExpressItem

                if(this._.has(express, 'expressOrderInstance')){

                  this.CDEK.data.packaging.setPackagingItem(express.expressOrderInstance.proform_packaging)

                  this.CDEK.setProducts(express.proformData)

                  this.setUserData(express)


                  this.$store.dispatch('getAddressData', {
                    'user_id': this.CDEK.getUserId(),
                    'delivery_service_id': CDEK_DELIVERY_SERVICE.id
                  }).then((response) => {
                    this.sendersCDEK = this.getRespData(response).senders
                  })

                  // if(express.expressOrderInstance.warehouse){
                  //   this.CDEK.setShippingWarehouse(express.expressOrderInstance.warehouse)
                  // } else {
                  //   this.getDefaultWarehouse()
                  // }

                }
              })
              break
          }
        }

      },

      setUserData(express) {
        if(this.isCurrentUserRoleUser){
          let userData = this.$store.getters.GET_COMMON_AUTHORIZED.user
          this.CDEK.setCDEKUser(userData)

          if(this.isBelarus)
            this.getDefaultWarehouse('MINSK01')

          if(this.isSNGGroup)
            this.getDefaultWarehouse('MOSCOW01')
        }

        if(this.isAdmin){
          let user = express.expressOrderInstance.user
          let countryGroup = user.user_personal_contact.country?.country_group
          this.CDEK.setCDEKUser(user)

          if(this.isBelarusByUserData(countryGroup)){
            this.getDefaultWarehouse('MINSK01')
          }

          if(this.isSNGGroupByUserData(countryGroup)){
            this.getDefaultWarehouse('MOSCOW01')
          }
        }


      }
    },
  }
</script>

<style scoped>

</style>
