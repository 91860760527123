import { render, staticRenderFns } from "./CDEKTableMobile.vue?vue&type=template&id=522d4c76&scoped=true"
import script from "./CDEKTableMobile.vue?vue&type=script&lang=js"
export * from "./CDEKTableMobile.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "522d4c76",
  null
  
)

export default component.exports