<template>
  <div class="fragment">
    <CDEKTableUser
        :countFilterParams="countFilterParams"
        :filterGetParams="filterGetParams"
        @resetFilter="resetFilter"
        @showMore="showMore"
        @changeFilter="changeFilter"
        @reload="reload"
    />
  </div>
</template>

<script>
  import CDEKTableUser from "./CDEKTableUser/CDEKTableUser";
  import {routeFilter} from "../../../../../mixins/routeFilterMixins/routeFilter";
  import {CDEK_DELIVERY_SERVICE} from "../../../../../staticData/staticVariables";

  export default {
    name: "CDEKTable",
    components: {
      CDEKTableUser,
    },

    mixins: [routeFilter],

    watch: {
      loadUserRoleAndAuthorizedData() {
        if(this.isCurrentUserRoleUser){
          this.$router.push(this.$store.getters.GET_PATHS.notFound)
        }
      }
    },

    data(){
      return {
        forPage: this.$store.getters.getOrdersInternalForPage,
        page: 1,
        onePage: 1,

        filterId: '',
        filterUserId: '',
        filterUserName: '',
        filterDate: [],
        filterTrackingNumber: '',

        countFilterParams: 0,
        filterGetParams: {},
      }
    },

    mounted() {

      if(this.loadUserRoleAndAuthorizedData && this.isCurrentUserRoleUser){
        this.$router.push(this.$store.getters.GET_PATHS.notFound)
      }

      this.filter()
    },

    beforeDestroy() {
      this.$store.commit('setOrdersInternal',{ordersInternal: []})
      this.$store.commit('setOrdersInternalCommonList',{ordersInternalCommonList: []})
    },

    methods: {

      getFilterParam() {
        this.filterGetParams = {
          page: this.$route.query.page,
          count: this.$route.query.count,

          id: this.$route.query.id,
          userId: this.$route.query.userId,
          userName: this.$route.query.userName,
          date: this.$route.query.date,
          trackingNumber: this.$route.query.trackingNumber,

        }
      },

      refreshTable() {
        this.filter()
      },

      filter(next = false) {
        this.getFilterParam()

        let url

        // generate main filter if has get params in URL
        let generateMainFilter = this.generateMainFilterFromUrl(next, this)

        // generate other filter if has get params in URL
        this.generateParamsFilterFromUrl(this)

        // Generate filter URL
        url = this.generateFilterUrl(
            generateMainFilter.page,
            generateMainFilter.forPage
        )

        if (next) this.$store.commit('setNextOrdersInternalPage', true)
        this.$store.dispatch('fetchOrdersInternal', url).then(() => {
          this.$store.commit('setNextOrdersInternalPage', false)
          this.$store.commit('setGlobalUpdateTable', true)
        })

        this.checkCountFilter(['userName'])
      },

      generateFilterUrl(page, forPage) {
        let myQuery = this.createQueryBuilderObject({withUserNewMark: true})

        this.generateFilterQueryParams(
            myQuery,
            {
              filterUserId: 'ointUserId',
              filterId: 'ointId',
              filterDate: 'ointBetweenCreatedAt',
              filterTrackingNumber: 'ointTrackingNumber',
            },
        )

        if (this.filterDate.length > 0) {
          let date = this.generateDateForFilter(this.filterDate, 'YYYY-MM-DD')

          myQuery.whereIn(
              'ointBetweenCreatedAt',
              date
          )
        }
        //
        // if (this.filterShipped.length > 0) {
        //   let date = this.generateDateForFilter(this.filterShipped, 'YYYY-MM-DD')
        //
        //   myQuery.whereIn(
        //       'ointBetweenShippedAt',
        //       date
        //   )
        // }


        myQuery.where('ointDeliveryServiceId', CDEK_DELIVERY_SERVICE.id)

        return this.generateGetParamsFromQuery(myQuery, forPage, page)


      },

    }

  }
</script>

<style scoped>

</style>
