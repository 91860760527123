<template>

  <div class="fragment">
    <CDEKEditingPage
        :cdek="CDEK"
        :sendersCDEK="sendersCDEK"
        @edit="save(true, true)"
        @getDeliveryPrice="getDeliveryPrice"
        @changeOldSenders="changeOldSenders"
    />

    <PayOrderPopup
        v-if="payOrderPopup"
        :orderPrice="orderPrice"
        :disableBtn="$store.getters.getCDEKBtn || $store.getters.getOrdersInternalBtn"
        @close="changePayOrderPopup(false)"
        @createLabel="save(false, true)"
    />

  </div>


</template>

<script>
  import CDEKEditingPage from "./CDEKEditingPage/CDEKEditingPage";
  import {cdekOrdersMixins} from "../../../../../mixins/internalOrdersMixins/сdekOrdersMixin";
  import {CDEK} from "../../models/CDEK";
  import PayOrderPopup from "../../../../coreComponents/Popups/PayOrderPopup/PayOrderPopup";
  import {CDEK_DELIVERY_SERVICE} from "../../../../../staticData/staticVariables";

  export default {
    name: "CDEKEditing",

    components: {
      CDEKEditingPage,
      PayOrderPopup,
    },

    mixins: [cdekOrdersMixins],

    watch: {
      loadUserRoleAndAuthorizedData() {
        this.getUserData()
      }
    },

    data() {
      return {
        CDEK: new CDEK(),

        payOrderPopup: false,

        orderPrice: -1,

        sendersCDEK: [],
      }
    },

    mounted() {

      this.CDEK.setId(this.$route.params.id)

      if (this.loadUserRoleAndAuthorizedData) {
        this.getUserData()
      }


    },

    methods: {
      
      changeOldSenders(item) {
        this.CDEK.setOldSenderData(item)
      },

      getUserData() {


        this.$store.dispatch('getOrdersInternalEdit', this.CDEK.getId()).then(() => {
          let order = this.$store.getters.getOrdersInternalItem.orderInternal
          this.CDEK.setItem(order)

          this.CDEK.setShippingWarehouse(order.recipient_warehouse)

          this.$store.dispatch('getAddressData', {
            'user_id': this.CDEK.getUserId(),
            'delivery_service_id': CDEK_DELIVERY_SERVICE.id
          }).then((response) => {
            this.sendersCDEK = this.getRespData(response).senders
          })

          // if(this.isCurrentUserRoleUser){
          //
          //   if(this.isBelarus)
          //     this.getDefaultWarehouse('MINSK01')
          //
          //
          //   if(this.isSNGGroup)
          //     this.getDefaultWarehouse('MOSCOW01')
          //
          // }
          // if(this.isAdmin){
          //
          //   if(this.isBelarusByUserData(this.CDEK.data.user))
          //     this.getDefaultWarehouse('MINSK01')
          //
          //   if(this.isSNGGroupByUserData(this.CDEK.data.user))
          //     this.getDefaultWarehouse('MOSCOW01')
          // }
        }).catch(error => this.createErrorLog(error))


      },
    },
  }
</script>

<style scoped lang="scss">
  @import "../../../../../scss/mixins/mixins";


</style>
