<template>
  <div class="table-card__item-content"
       :class="{show: show}"
  >
    <div class="table-card__item-head">
      <div class="table-card__item-head-row">
        <div class="table-card__item-number">
          #{{item.id}}
        </div>
      </div>
      <div class="table-card__item-head-row">
        <div class="table-card__item-head-txt">
          {{ item.created_at | moment("DD MMM, YYYY") }}
        </div>
      </div>
    </div>
    <transition name="slide">
      <div class="table-card__item-body"
           v-if="show"
      >
        <div class="table-card__item-row custom-row">
          <div class="table-card__item-col custom-col custom-col--50">
            <div class="table-card__item-label">
              {{$t('poshta_Created.localization_value.value')}}
            </div>
            <div class="table-card__item-info">
              {{ item.created_at | moment("DD MMM, YYYY") }}
            </div>
          </div>

          <div class="table-card__item-col custom-col" v-if="isAdmin">
            <div class="table-card__item-label">
              {{$t('common_user.localization_value.value')}}
            </div>
            <div class="table-card__item-info">
              <TableUserColumn
                      :item="item"
                      :mobile="true"
              />
            </div>
          </div>


          <div class="table-card__item-col custom-col ">
            <div class="table-card__item-label">
              {{$t('poshta_documentNumber.localization_value.value')}}
            </div>
            <div class="table-card__item-info d-flex align-items-center">
              <div class="d-flex align-items-center">
                <InternalTrackingNumber
                        :item="item"
                        @reload="$emit('reload')"
                />
              </div>
            </div>
          </div>

          <div class="table-card__item-col custom-col ">
            <div class="table-card__item-label">
              {{$t('poshta_Shipping.localization_value.value')}}
            </div>
            <div class="table-card__item-info d-flex align-items-center">
              <div class="d-flex align-items-center">
                <ValueHelper
                        :value="item.shipping_cost"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="table-card__item-btn">
          <div class="table-card__item-btn-i"
               v-if="(item.order_internal_status_id !== INTERNAL_STATUSES.COMPLETE.id) ||
                              $store.getters.getIsAdminRights === 'admin'"
          >
            <LinkButton
                    :value="$t('common_edit.localization_value.value')"
                    :type="'edit'"
                    :link="$store.getters.GET_PATHS.cdek + '/' + item.id"
            />
          </div>
          <div class="table-card__item-btn-i"
               v-if="(item.order_internal_status_id !== INTERNAL_STATUSES.COMPLETE.id)"
          >
            <LinkButton
                    :value="$t('common_delete.localization_value.value')"
                    :type="'delete'"
                    @click.native="$emit('removeOrderInternal')"
            />
          </div>
          <div class="table-card__item-btn-i"
               v-if="(item.order_internal_status_id !== INTERNAL_STATUSES.COMPLETE.id)"
          >
            <LinkButton
                    :value="$t('express_CDEKCreateLabel.localization_value.value')"
                    :type="'dog'"
                    @click.native="$emit('createLabel')"
            />
          </div>
          <div class="table-card__item-btn-i"
               v-if="(item.order_internal_status_id === INTERNAL_STATUSES.COMPLETE.id)"
          >
            <LinkButton
                    :link="$store.getters.GET_PATHS.getPdfFile + '/' + getBarcodeUUID(item.file_entries)"
                    :value="$t('express_CDEKBarcode.localization_value.value')"
                    :type="'dog'"
                    :target="'_blank'"
            />
          </div>
          <div class="table-card__item-btn-i"
               v-if="(item.order_internal_status_id === INTERNAL_STATUSES.COMPLETE.id)"
          >
            <LinkButton
                    :link="$store.getters.GET_PATHS.getPdfFile + '/' + getCDEKWaybillUUID(item.file_entries)"
                    :value="$t('express_CDEKWaybill.localization_value.value')"
                    :type="'dog'"
                    :target="'_blank'"
            />
          </div>
        </div>
      </div>
    </transition>
    <div class="table-card__item-footer">
      <div class="table-card__item-show"
           @click="show = !show"
      >
        {{$t('common_showMore.localization_value.value')}}
      </div>
    </div>
  </div>
</template>

<script>
  import LinkButton from "../../../../../../../UI/buttons/LinkButton/LinkButton";
  import ValueHelper from "../../../../../../../coreComponents/ValueHelper/ValueHelper";
  import {INTERNAL_STATUSES} from "../../../../../../../../staticData/staticVariables";
  import TableUserColumn from "../../../../../../../coreComponents/TableComponents/TableUserColumn/TableUserColumn";
  import InternalTrackingNumber
    from "../../../../../../../coreComponents/TableComponents/InternalTrackingNumber/InternalTrackingNumber";
  import {cdekOrdersTableMixins} from "../../../../../../../../mixins/internalOrdersMixins/сdekOrdersMixin";

  export default {
    name: "CDEKTableMobile",

    components: {
      InternalTrackingNumber,
      TableUserColumn,
      ValueHelper,
      LinkButton,
    },

    mixins: [cdekOrdersTableMixins],

    props: {
      item: Object,
    },

    data() {
      return {
        INTERNAL_STATUSES: INTERNAL_STATUSES,
        show: false,
      }
    }

  }
</script>

<style scoped>

</style>
