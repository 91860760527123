<template>

  <div class="fragment">
    <div class="detail-page">
      <div class="detail-page__left"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['poshta_order','poshta_Created'])"></div>
        <CardLeftBlock
            :name="$t('poshta_order.localization_value.value')"
            :value="`#${CDEK.data.id}`"
            :backgroundImage="'orders'"
        >
          <template slot="body">
            <div class="d-flex mt-3">
              <div class="card-detail-left__name">
                {{ $t('poshta_Created.localization_value.value') }}
              </div>
              <div class="card-detail-left__date ml-2">{{CDEK.data.shippingDate}}</div>
            </div>

            <div class="d-flex mt-3" v-if="CDEK.data.trackingNumber !== ''">
              <div class="card-detail-left__name">
                {{ $t('poshta_TrackNumber.localization_value.value') }}
              </div>
              <div class="card-detail-left__date ml-2">{{CDEK.data.trackingNumber}}</div>
            </div>

          </template>
        </CardLeftBlock>
      </div>

      <div class="detail-page__right">
        <CardRightBlock>
          <template slot="header">
            <div class="order-create__head mb-0">
              <div class="order-create__head-line">
                <div class="order-create__header-col"
                     v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                  <div class="admin-edit" @click="editTranslate(['poshta_backTo',])"></div>
                  <router-link :to="$store.getters.GET_PATHS.expressOrders" class="order-create__head-back">
                    <LinkBack
                        :value="$t('poshta_backTo.localization_value.value')"
                    />
                  </router-link>
                </div>
              </div>

              <div class="order-create__head-title"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['poshta_orderCDEK'])"></div>
                {{ $t('poshta_orderCDEK.localization_value.value') }}
              </div>
            </div>
          </template>

          <template slot="body">
            <div class="order-create__section">
              <div class="order-create__section-label section-label"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['poshta_PersonalInformation',])"></div>
                {{$t('poshta_PersonalInformation.localization_value.value')}}
              </div>

              <div class="order-create__row custom-row">
                <div class="order-create__col custom-col">
                  <div class="order-create__table block-table">
                    <div class="block-table__row">
                      <div class="block-table__col block-table__col--20 block-table__col--sm-50">
                        <div class="block-table__label"
                             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                          <div class="admin-edit" @click="editTranslate(['poshta_contactName',])"></div>
                          {{$t('poshta_contactName.localization_value.value')}}
                        </div>
                        <div class="block-table__content">
                          <ValueHelper
                              :value="CDEK.data.personalInformation.information.personalName"
                          />
                        </div>
                      </div>
                      <div class="block-table__col block-table__col--20 block-table__col--sm-50">
                        <div class="block-table__label"
                             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                          <div class="admin-edit" @click="editTranslate(['common_phone',])"></div>
                          {{$t('common_phone.localization_value.value')}}
                        </div>
                        <div class="block-table__content">
                          <ValueHelper
                              :value="CDEK.data.personalInformation.information.personalPhone"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>


              <div class="order-create__section-label section-label"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['poshta_SenderAddress',])"></div>
                {{ $t('poshta_SenderAddress.localization_value.value') }}
              </div>

              <div class="order-create__row custom-row">
                <div class="order-create__col custom-col">
                  <div class="order-create__table block-table">
                    <div class="block-table__row">
                      <div class="block-table__col block-table__col--20 block-table__col--sm-50">
                        <div class="block-table__label"
                             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                          <div class="admin-edit" @click="editTranslate(['poshta_city',])"></div>
                          {{$t('poshta_city.localization_value.value')}}
                        </div>
                        <div class="block-table__content">
                          <ValueHelper
                              :value="CDEK.data.selectedCity.cityName"
                          />
                        </div>
                      </div>
                      <div class="block-table__col block-table__col--20 block-table__col--sm-50">
                        <div class="block-table__label"
                             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                          <div class="admin-edit" @click="editTranslate(['poshta_office',])"></div>
                          {{$t('poshta_office.localization_value.value')}}
                        </div>
                        <div class="block-table__content">
                          <ValueHelper
                              :value="CDEK.data.selectedOffice.address"
                          />
                        </div>
                      </div>
                      <div class="block-table__col block-table__col--20 block-table__col--sm-50">
                        <div class="block-table__label"
                             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                          <div class="admin-edit" @click="editTranslate(['common_zipCode',])"></div>
                          {{$t('common_zipCode.localization_value.value')}}
                        </div>
                        <div class="block-table__content">
                          <ValueHelper
                              :value="CDEK.data.zip"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>


              <div class="order-create__section-label section-label"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['poshta_ProductInformation',])"></div>
                {{$t('poshta_shippingInformation.localization_value.value')}}
              </div>

              <div class="order-create__row custom-row">
                <div class="order-create__col custom-col">
                  <div class="order-create__table block-table">
                    <div class="block-table__row">
                      <div class="block-table__col block-table__col--20 block-table__col--sm-50">
                        <div class="block-table__label"
                             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                          <div class="admin-edit" @click="editTranslate(['common_weightKg',])"></div>
                          {{$t('common_weightKg.localization_value.value')}}
                        </div>
                        <div class="block-table__content">
                          <ValueHelper
                              :value="CDEK.data.packaging.packaging.weight"
                          />
                        </div>
                      </div>
                      <div class="block-table__col block-table__col--20 block-table__col--sm-50">
                        <div class="block-table__label"
                             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                          <div class="admin-edit" @click="editTranslate(['common_heightCm',])"></div>
                          {{$t('common_heightCm.localization_value.value')}}
                        </div>
                        <div class="block-table__content">
                          <ValueHelper
                              :value="CDEK.data.packaging.packaging.height"
                          />
                        </div>
                      </div>
                      <div class="block-table__col block-table__col--20 block-table__col--sm-50">
                        <div class="block-table__label"
                             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                          <div class="admin-edit" @click="editTranslate(['common_width, cm',])"></div>
                          {{$t('common_width, cm.localization_value.value')}}
                        </div>
                        <div class="block-table__content">
                          <ValueHelper
                              :value="CDEK.data.packaging.packaging.width"
                          />
                        </div>
                      </div>
                      <div class="block-table__col block-table__col--20 block-table__col--sm-50">
                        <div class="block-table__label"
                             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                          <div class="admin-edit" @click="editTranslate(['common_lengthCm',])"></div>
                          {{$t('common_lengthCm.localization_value.value')}}
                        </div>
                        <div class="block-table__content">
                          <ValueHelper
                              :value="CDEK.data.packaging.packaging['length']"
                          />
                        </div>
                      </div>

                    </div>

                    <div class="block-table__row">
                      <div class="block-table__col block-table__col--20 block-table__col--sm-50">
                        <div class="block-table__label"
                             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                          <div class="admin-edit" @click="editTranslate(['poshta_ShippingDate',])"></div>
                          {{$t('poshta_ShippingDate.localization_value.value')}}
                        </div>
                        <div class="block-table__content">
                          <ValueHelper
                              :value="CDEK.data.shippingDate"
                          />
                        </div>
                      </div>
                      <div class="block-table__col block-table__col--20 block-table__col--sm-50">
                        <div class="block-table__label"
                             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                          <div class="admin-edit" @click="editTranslate(['poshta_ShipToWarehouse',])"></div>
                          {{$t('poshta_ShipToWarehouse.localization_value.value')}}
                        </div>
                        <div class="block-table__content">
                          {{CDEK.data.shippingWarehouse.recipient_city_name}}
                        </div>
                      </div>
                      <div class="block-table__col block-table__col--20 block-table__col--sm-50">
                        <div class="block-table__label"
                             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                          <div class="admin-edit" @click="editTranslate(['poshta_DeclaredValue',])"></div>
                          {{$t('poshta_DeclaredValue.localization_value.value')}}
                        </div>
                        <div class="block-table__content">
                          {{ CDEK.data.assessedValue}}
                        </div>
                      </div>

                      <div class="block-table__col block-table__col--20 block-table__col--sm-50">
                        <div class="block-table__label"
                             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                          <div class="admin-edit" @click="editTranslate(['poshta_Shipping',])"></div>
                          {{$t('poshta_Shipping.localization_value.value')}}
                        </div>
                        <div class="block-table__content">
                          <ValueHelper
                                  :value="CDEK.data.shippingCost"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

<!--              <div class="order-create__section-label section-label">-->
<!--                Cargo description (up to 50 symbols)-->
<!--              </div>-->

<!--              <div class="block-table__row">-->
<!--                <div class="block-table__col block-table__col&#45;&#45;66 block-table__col&#45;&#45;sm-100">-->
<!--                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed in blandit nisl. Integer eu fermentum neque. Phasellus justo risus, placerat sed tortor non, tristique lacinia elit. Aenean mattis finibus augue sed gravida. Quisque et varius nisl. Nunc mauris dui, ullamcorper eget nunc nec, consequat bibendum nunc-->
<!--                </div>-->
<!--              </div>-->
            </div>
          </template>
          <template slot="footer">
            <div class="order-create__footer">

              <div class="order-create__footer-link"
                   v-if="$store.getters.getOrdersInternalItem.orderInternal &&
                  ($store.getters.getOrdersInternalItem.orderInternal.order_internal_status_id !== INTERNAL_STATUSES.COMPLETE.id)"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit"
                     @click="editTranslate(['common_deleteOrder '])"></div>

                <span class="site-link site-link--alt"
                      @click="removeOrderInternal(CDEK.data.id, INTERNAL_DELIVERY_SERVICES.CDEK_DELIVERY_SERVICE.id)"
                >
                  {{ $t('common_deleteOrder.localization_value.value') }}
                </span>
              </div>


              <!--<div class="order-create__footer-btn ml-auto"-->
                   <!--v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
                <!--<div class="admin-edit"-->
                     <!--@click="editTranslate(['common_createACopy'])"></div>-->
                <!--<MainButton-->
                    <!--class="order-create__footer-btn-i wfc"-->
                    <!--@click.native="copy"-->
                    <!--:value="$t('common_createACopy.localization_value.value')"-->
                <!--/>-->
              <!--</div>-->

            </div>
          </template>
        </CardRightBlock>

      </div>

    </div>
  </div>

</template>

<script>
  import {cdekOrdersMixins} from "../../../../../mixins/internalOrdersMixins/сdekOrdersMixin";
  import {CDEK} from "../../models/CDEK";

  import CardLeftBlock from "../../../../coreComponents/CardLeftBlock/CardLeftBlock.vue";
  import CardRightBlock from "../../../../coreComponents/CardRightBlock/CardRightBlock.vue";
  import LinkBack from "../../../../UI/buttons/LinkBack/LinkBack";
  // import MainButton from "../../../../UI/buttons/MainButton/MainButton";
  // import RadioDefault from "../../../../UI/radiobuttons/RadioDefault/RadioDefault";
  import ValueHelper from "../../../../coreComponents/ValueHelper/ValueHelper";
  import {INTERNAL_DELIVERY_SERVICES, INTERNAL_STATUSES} from "../../../../../staticData/staticVariables";

  export default {
    name: "CDEKShow",

    components: {
      CardLeftBlock,
      CardRightBlock,
      LinkBack,
      // MainButton,
      // RadioDefault,
      ValueHelper,
    },

    mixins: [cdekOrdersMixins],

    data() {
      return {
        CDEK: new CDEK(),

        INTERNAL_DELIVERY_SERVICES: INTERNAL_DELIVERY_SERVICES,
        INTERNAL_STATUSES: INTERNAL_STATUSES,

        item: '',
      }
    },

    mounted() {

      this.CDEK.setId(this.$route.params.id)

      this.$store.dispatch('getOrdersInternal', this.CDEK.getId()).then(() => {
        this.CDEK.setItem(this.$store.getters.getOrdersInternalItem.orderInternal)
      }).catch(error => this.createErrorLog(error))

    },

    methods: {
      copy() {
        this.copyOrder(this.CDEK.getId()).then(() => {
          this.$router.push(this.$store.getters.GET_PATHS.express)
        })
      }
    },
  }
</script>

<style scoped lang="scss">
  @import "../../../../../scss/mixins/mixins";


</style>
